<template>
  <div>
    <div class="mains">
<!--      <div class="main-left">-->
<!--        <img src="../../../public/pc.png" alt="" width="300"/>-->
<!--      </div>-->
      <div class="main-right">
        <div class="form">
          <h3>填写信息，获取免费试用名额</h3>
          <p>客服人员会尽快与您取得联系，安排试用</p>
          <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
          >
            <el-form-item label="姓名" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="ruleForm.phone"></el-input>
            </el-form-item>
            <div class="yz">
              <el-form-item label="手机验证" prop="phoneYZ">
                <el-input v-model="ruleForm.phoneYZ"></el-input>
              </el-form-item>
              <el-button
                  class="getCode"
                  :class="{ activeCode: codeFlag }"
                  @click="getCode"
              >
                {{ codeTxt }}
              </el-button>
            </div>

            <el-form-item label="企业名称" prop="company">
              <el-input v-model="ruleForm.company"></el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="submitForm('ruleForm')">立即获取</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "register",
  data() {
    return {
      codeTxt: "获取验证码",
      codeFlag: true,
      ruleForm: {
        name: "",
        phone: "",
        phoneYZ: "",
        company: "",
        code: "",
      },
      rules: {
        name: [
          {required: true, message: "请输入姓名", trigger: "blur"},
          {min: 2, message: "长度 在 2个字符以上", trigger: "blur"},
        ],
        phone: [
          {required: true, message: "请输入手机号", trigger: "blur"},
          {min: 11, max: 11, message: "长度在 11 个字符", trigger: "blur"},
        ],
        phoneYZ: [
          {required: true, message: "请输入验证码", trigger: "blur"},
          {min: 4, max: 4, message: "长度在 4 个字符", trigger: "blur"},
        ],
        company: [
          {required: true, message: "请输入公司名称", trigger: "blur"},
          {min: 4,  message: "长度在 4 个字符以上", trigger: "blur"},
        ],
      },
    };
  },
  mounted() {
    console.log("download", Boolean(this._isMobile()));
    if (this._isMobile()) {
      // 为true是移动端,跳至移动端页面
      console.log("yi移动端");
      this.$router.replace('/mobileLogin')
    } else {
      // 为false是pc端，跳至pc端页面
      console.log("PC端");
      this.$router.replace('/register')
    }
  },
  methods: {
    _isMobile() {
      let plat = navigator.userAgent.match( // 判断不同端
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return plat;
    },
    open1(){
      this.$notify({
        title: '请求成功',
        message: '请求成功',
        type: 'success'
      });
    },
    open2(){
      this.$notify.error({
        title: '请求失败',
        message: '请求失败'
      });
    },
    //  获取验证码
    getCode() {
      if (this.codeFlag == false) {
        return;
      }
      this.send();
    },
    //send 获取验证码
    send() {
      this.axios.post('https://sytonadmin.kbbcloud.com/index.php/api/smsadmin/send', {
        mobile: this.ruleForm.phone
      })
          .then(res=>{
            console.log(res);
            if(res.data.code == 0){
              console.log('不成功',res.data.msg)
              this.$alert(res.data.msg, res.data.msg, {
                confirmButtonText: '确定',
                customClass:'msgBox',
                callback: action => {
                  this.$message({
                    type: 'info',
                    message: `action: ${ action }`
                  });
                }
              });
            }else{
              this.codeFlag = false;
              console.log(" this.codeFlag ", this.codeFlag )
              var time = 60
              this.codeTxt = "重新获取" + time;
              var timer = setInterval(() => {
                if (time == 1) {
                  this.codeTxt = "获取验证码"
                  this.codeFlag = true
                  clearInterval(timer)
                } else {
                  time--
                  this.codeTxt = "重新获取" + time;
                }

              }, 1000)
              this.$alert(res.data.msg, res.data.msg, {
                confirmButtonText: '确定',
                callback: action => {
                  this.$message({
                    type: 'info',
                    message: `action: ${ action }`
                  });
                }
              });
            }
          })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("valid",valid)
          this.axios.post('https://sytonadmin.kbbcloud.com/index.php/api/register/register',{
            truename:this.ruleForm.name,
            company:this.ruleForm.company,
            mobile:this.ruleForm.phone,
            captcha:this.ruleForm.phoneYZ
          }).then(res=>{
            console.log("res",res)
            if (res.data.code == 1){
              this.open1();
            }else{
              this.open2();
            }
          })
        } else {
          this.open2();
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style>
.el-message-box{
  width: 100%;
}
</style>
<style lang="scss" scoped>
body {
  background: #eaeaea;

  .mains {
    .main-left {
    }

    .main-right {
      box-shadow: 5px 5px 5px 5px #888888;
      margin: 20px 5px;
      .form {
        padding: 30px;
      }
    }
  }
}

.yz {
  display: flex;

  .getCode {

    width: 180 rpx;
    background: #f7f7f7;
    margin-top: 20 rpx;

    color: #666;
    height: 40px;
    border-radius: 23px;
    align-items: center;
    line-height: 40px;
    font-size: 14px;
    margin-left: 10px;
    padding: 0 15px;
    border: none;
    text-align: center;

  }

  .getCode.activeCode {
    background: #409eff;
    height: 40px;
    border-radius: 23px;
    align-items: center;
    line-height: 40px;
    font-size: 14px;
    margin-left: 10px;
    padding: 0 15px;
    color: #fff;
  }
}
.msgBox{
  height: 200px;
  width: 200px;
}
.el-message-box{
  width: 200px;
}
</style>
